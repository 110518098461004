/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    div: "div",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<span\n      class=\"gatsby-resp-image-wrapper\"\n      style=\"position: relative; display: block; margin-left: auto; margin-right: auto; max-width: 300px; \"\n    >\n      <span\n    class=\"gatsby-resp-image-background-image\"\n    style=\"padding-bottom: 56.024096385542165%; position: relative; bottom: 0; left: 0; background-image: url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAECAwX/xAAXAQADAQAAAAAAAAAAAAAAAAABAgME/9oADAMBAAIQAxAAAAHmKnsjkQIf/8QAGBAAAgMAAAAAAAAAAAAAAAAAAAERIDH/2gAIAQEAAQUCpqk//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/AWx//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGhABAQADAQEAAAAAAAAAAAAAAQARITHh8P/aAAgBAQABPyFLHySZ8Z1Y2vZQ9v/aAAwDAQACAAMAAAAQu/8A/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAEDAQE/EKmv/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEhYf/aAAgBAgEBPxBqmB//xAAaEAEAAwEBAQAAAAAAAAAAAAABABEhUTGR/9oACAEBAAE/EBd0exDlj0sqClr6k0zJSgG3vkWBg7s//9k='); background-size: cover; display: block;\"\n  ></span>\n  <img\n        class=\"gatsby-resp-image-image\"\n        alt=\"how Alaska got its name\"\n        title=\"how Alaska got its name\"\n        src=\"/static/d852ec1940ac96e756cf97a8b85e7e20/73b64/alaska-flag.jpg\"\n        srcset=\"/static/d852ec1940ac96e756cf97a8b85e7e20/4d5fb/alaska-flag.jpg 166w,\n/static/d852ec1940ac96e756cf97a8b85e7e20/73b64/alaska-flag.jpg 300w\"\n        sizes=\"(max-width: 300px) 100vw, 300px\"\n        style=\"width:100%;height:100%;margin:0;vertical-align:middle;position:absolute;top:0;left:0;\"\n        loading=\"lazy\"\n        decoding=\"async\"\n      />\n    </span>"
    }
  })), "\n", React.createElement(_components.p, null, "The least populated but largest state in the U.S. was purchased from the Russians in 1867. "), "\n", React.createElement(_components.p, null, "The name actually comes from the Aleut word ", React.createElement(_components.strong, null, "Alaxsxaq"), " which means “the object towards which the action of the sea is directed”."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
